export default class HKSARVideo {
    constructor(entityId, videoId, sections = []) {
        this.target = document.getElementById(entityId);
        this.video = document.getElementById(videoId);

        if (!this.target || !this.video) {
            console.error(`Element(s) not found: target (${entityId}), video (${videoId})`);
            return;
        }

        this.sections = sections;
        this.currentSection = this.sections[0];
        this.scanned = false;

        this.init();
    }

    init = () => {
        
        // Set event listeners for AR target found and lost events
        this.target.addEventListener("targetFound", this.onTargetFound);
        this.target.addEventListener("targetLost", this.onTargetLost);

        // Synchronize video time with sections
        this.video.addEventListener("timeupdate", this.onTimeUpdate);
    }

    onTargetFound = () => {
        this.setScanned(true);
        const mainCamera = window.experience.mainCamera;

        if (mainCamera)
        mainCamera.style.opacity = 0.5;

        this.playVideo();
    }

    onTargetLost = () => {
        const mainCamera = window.experience.mainCamera;

        if (mainCamera)
            mainCamera.style.opacity = 1;

        this.pauseVideo();
        this.jumpToRandomSection();

        if (window.experience.scanCount >= window.experience.scanCountMax) {

            window.experience.videoCards[window.experience.scanCount].parentElement.style.animation = "glow 0.5s infinite alternate";
            window.experience.videoCards[window.experience.scanCount].parentElement.style.cursor = "pointer";
            
            window.experience.videoCards[window.experience.scanCount].parentElement.addEventListener("click", () => {
                
                window.experience.videoCards[window.experience.scanCount].parentElement.style.display = "none";
                window.experience.frame.style.display = "block";
                
                navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" } })
                .then(stream => {
                    const videoTracks = stream.getVideoTracks();
                    if (videoTracks.length > 0) {
                        mainCamera.style.transform = "scaleX(-1)";
                        mainCamera.srcObject = stream;
                        mainCamera.play();
                    }
                })
                .catch(error => {
                    console.error("Error accessing front camera:", error);
                });

                window.experience.arSystem.pause(true);
                window.experience.arUIScanning.style.display = "none";        

            });


        }

    }

    onTimeUpdate = () => {
        // Jump to a new section if the current one is finished
        if (this.video.currentTime >= this.currentSection.end) {
            this.jumpToRandomSection();
        }
    }

    setScanned = (scanned) => {
        if (scanned !== this.scanned) {
            this.scanned = scanned;
            if (scanned && window.experience) {
                window.experience.scanCount++;
                window.experience.videoCards[window.experience.scanCount].classList.add("active");
            }
        }
    }

    getRandomSection = () => {
        // Get a random section from the available sections
        return this.sections[Math.floor(Math.random() * this.sections.length)];
    }

    jumpToRandomSection = () => {
        // Jump to a randomly selected section and set it as the current one
        this.currentSection = this.getRandomSection();
        this.video.currentTime = this.currentSection.start;
    }

    playVideo = () => {
        this.video.play();
    }

    pauseVideo = () => {
        this.video.pause();
    }

    // Clean up event listeners to prevent memory leaks
    destroy = () => {
        this.target.removeEventListener("targetFound", this.onTargetFound);
        this.target.removeEventListener("targetLost", this.onTargetLost);
        this.video.removeEventListener("timeupdate", this.onTimeUpdate);
    }
}
