import HKSARVideo from "../Utils/HKSARVideo";

let instance = null;

export default class Experience {
    constructor() {
        // Singleton
        if (instance) {
            return instance;
        }
        instance = this;

        // Global access
        window.experience = this;

        this.scanCount = 0;
        this.scanCountMax = 6;

        this.HKSARVideos = [
            new HKSARVideo("artTarget", "artVideo", [
                { start: 0, end: 19 },
                { start: 20, end: 36 },
                { start: 38, end: 57 }
            ]),
            new HKSARVideo("foodTarget", "foodVideo", [
                { start: 0, end: 18 },
                { start: 19, end: 37 },
                { start: 38, end: 66 }
            ]),
            new HKSARVideo("infraTarget", "infraVideo", [
                { start: 0, end: 39 },
                { start: 40, end: 56 },
                { start: 58, end: 84 }
            ]),
            new HKSARVideo("lifestyleTarget", "lifestyleVideo", [
                { start: 0, end: 23 },
                { start: 24, end: 50 },
                { start: 51, end: 71 }
            ]),
            new HKSARVideo("natureTarget", "natureVideo", [
                { start: 0, end: 19 },
                { start: 20, end: 39 },
                { start: 40, end: 60 }
            ]),
            new HKSARVideo("workTarget", "workVideo", [
                { start: 0, end: 19 },
                { start: 20, end: 36 },
                { start: 37, end: 67 }
            ])
        ];

        const closeButtons = document.getElementById("closeInfo");
        const info = document.getElementById("info");
        const infoButton = document.getElementById("infoButton");
        closeButtons.addEventListener("click", () => {
            info.style.display = "none";
            infoButton.style.display = "flex";

        });

        infoButton.addEventListener("click", () => {
            info.style.display = "flex";
            infoButton.style.display = "none";
        });

        this.videoCards = document.getElementById("logo").children;

        const sceneEl = document.querySelector('a-scene');

        sceneEl.addEventListener("loaded", (event) => {
            this.arSystem = sceneEl.systems["mindar-image-system"];

            const startButton = document.getElementById("startButton");

            startButton.addEventListener("click", () => {
                this.arSystem.start();
                document.getElementById("startScreen").style.display = "none";
            });

        });

        sceneEl.addEventListener("arReady", (event) => {

            const lastElement = document.body.lastElementChild;

            if (lastElement.tagName === "VIDEO") {
                this.mainCamera = lastElement;
                document.body.style.backgroundColor = "black";
            }

            infoButton.style.display = "flex";

            this.arUIScanning = document.querySelector(".mindar-ui-scanning");
            
            this.videoCards[0].classList.add("active");

        });

        this.frame = document.getElementById("frame");
        
        document.getElementById("takePhoto").addEventListener("click", () => {

            const canvas = document.createElement("canvas");
            canvas.width = this.mainCamera.videoWidth;
            canvas.height = this.mainCamera.videoHeight;
            const context = canvas.getContext("2d");
            context.drawImage(this.mainCamera, 0, 0, canvas.width, canvas.height);
            
            context.translate(canvas.width, 0);
            context.scale(-1, 1);
            context.drawImage(this.mainCamera, 0, 0, canvas.width, canvas.height);
            
            // Add border
            context.strokeStyle = "white";
            context.lineWidth = 100;
            context.strokeRect(0, 0, canvas.width, canvas.height);
            
            // Add logo
            const logo = new Image();
            logo.src = "./logo-with-margin.jpg";
            logo.onload = () => {
                
                context.translate(canvas.width, 0);
                context.scale(-1, 1);
                const scale = 0.125;
                context.drawImage(logo, canvas.width / 2 - logo.width * scale / 2, canvas.height - logo.height * scale, logo.width * scale, logo.height * scale);
                
                // Convert canvas to image and trigger download
                const img = document.createElement("img");
                img.src = canvas.toDataURL("image/png");
                const a = document.createElement("a");
                a.download = "photo.png";
                a.href = img.src;
                a.click();
            };

        });


        this.init();
    }

    init() {
    }
}
